import { Circle } from "@svgdotjs/svg.js";
import Utilitarios from "./../Util";

const ParseCirculo = function (entidade, layers) {
  let x = entidade[0][0];
  let y = entidade[0][1];
  let raio = entidade[1] ?? null;
  let retorno = new Circle({ cx: x, cy: y, r: raio });
  retorno.fill("none");
  retorno.stroke(Utilitarios.StrokeObjeto(entidade[8], layers));
  retorno.transform(Utilitarios.TranformarEixoY);
  return retorno;
}

export default ParseCirculo;
