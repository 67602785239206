import { Polygon, Polyline } from "@svgdotjs/svg.js";
import Utilitarios from "./../Util";

const ParsePolilinha = function (entidade, layers) {
  let pontos = entidade[1].map((ponto) => [ponto[0], ponto[1]]);
  let retorno;
  if (entidade[2])
    retorno = new Polygon({ points: pontos });
  else
    retorno = new Polyline({ points: pontos });
  retorno.stroke(Utilitarios.StrokeObjeto(entidade[8], layers));
  retorno.fill("none");
  retorno.transform(Utilitarios.TranformarEixoY);
  return retorno;
}

export default ParsePolilinha;
