var ConversaoCores = {
  tabela: {
    0: [0, 0, 0],
    1: [255, 0, 0], 2: [255, 255, 0], 3: [0, 255, 0], 4: [0, 255, 255], 5: [0, 0, 255],
    6: [255, 0, 255], 7: [255, 255, 255], 8: [128, 128, 128], 9: [192, 192, 192], 10: [255, 0, 0],
    11: [255, 127, 127], 12: [165, 0, 0], 13: [165, 82, 82], 14: [127, 0, 0], 15: [127, 63, 63],
    16: [76, 0, 0], 17: [76, 38, 38], 18: [38, 0, 0], 19: [38, 19, 19], 20: [255, 63, 0],
    21: [255, 159, 127], 22: [165, 41, 0], 23: [165, 103, 82], 24: [127, 31, 0], 25: [127, 79, 63],
    26: [76, 19, 0], 27: [76, 47, 38], 28: [38, 9, 0], 29: [38, 23, 19], 30: [255, 127, 0],
    31: [255, 191, 127], 32: [165, 82, 0], 33: [165, 124, 82], 34: [127, 63, 0], 35: [127, 95, 63],
    36: [76, 38, 0], 37: [76, 57, 38], 38: [38, 19, 0], 39: [38, 28, 19], 40: [255, 191, 0],
    41: [255, 223, 127], 42: [165, 124, 0], 43: [165, 145, 82], 44: [127, 95, 0], 45: [127, 111, 63],
    46: [76, 57, 0], 47: [76, 66, 38], 48: [38, 28, 0], 49: [38, 33, 19], 50: [255, 255, 0],
    51: [255, 255, 127], 52: [165, 165, 0], 53: [165, 165, 82], 54: [127, 127, 0], 55: [127, 127, 63],
    56: [76, 76, 0], 57: [76, 76, 38], 58: [38, 38, 0], 59: [38, 38, 19], 60: [191, 255, 0],
    61: [223, 255, 127], 62: [124, 165, 0], 63: [145, 165, 82], 64: [95, 127, 0], 65: [111, 127, 63],
    66: [57, 76, 0], 67: [66, 76, 38], 68: [28, 38, 0], 69: [33, 38, 19], 70: [127, 255, 0],
    71: [191, 255, 127], 72: [82, 165, 0], 73: [124, 165, 82], 74: [63, 127, 0], 75: [95, 127, 63],
    76: [38, 76, 0], 77: [57, 76, 38], 78: [19, 38, 0], 79: [28, 38, 19], 80: [63, 255, 0],
    81: [159, 255, 127], 82: [41, 165, 0], 83: [103, 165, 82], 84: [31, 127, 0], 85: [79, 127, 63],
    86: [19, 76, 0], 87: [47, 76, 38], 88: [9, 38, 0], 89: [23, 38, 19], 90: [0, 255, 0],
    91: [127, 255, 127], 92: [0, 165, 0], 93: [82, 165, 82], 94: [0, 127, 0], 95: [63, 127, 63],
    96: [0, 76, 0], 97: [38, 76, 38], 98: [0, 38, 0], 99: [19, 38, 19], 100: [0, 255, 63],
    101: [127, 255, 159], 102: [0, 165, 41], 103: [82, 165, 103], 104: [0, 127, 31], 105: [63, 127, 79],
    106: [0, 76, 19], 107: [38, 76, 47], 108: [0, 38, 9], 109: [19, 38, 23], 110: [0, 255, 127],
    111: [127, 255, 191], 112: [0, 165, 82], 113: [82, 165, 124], 114: [0, 127, 63], 115: [63, 127, 95],
    116: [0, 76, 38], 117: [38, 76, 57], 118: [0, 38, 19], 119: [19, 38, 28], 120: [0, 255, 191],
    121: [127, 255, 223], 122: [0, 165, 124], 123: [82, 165, 145], 124: [0, 127, 95], 125: [63, 127, 111],
    126: [0, 76, 57], 127: [38, 76, 66], 128: [0, 38, 28], 129: [19, 38, 33], 130: [0, 255, 255],
    131: [127, 255, 255], 132: [0, 165, 165], 133: [82, 165, 165], 134: [0, 127, 127], 135: [63, 127, 127],
    136: [0, 76, 76], 137: [38, 76, 76], 138: [0, 38, 38], 139: [19, 38, 38], 140: [0, 191, 255],
    141: [127, 223, 255], 142: [0, 124, 165], 143: [82, 145, 165], 144: [0, 95, 127], 145: [63, 111, 127],
    146: [0, 57, 76], 147: [38, 66, 76], 148: [0, 28, 38], 149: [19, 33, 38], 150: [0, 127, 255],
    151: [127, 191, 255], 152: [0, 82, 165], 153: [82, 124, 165], 154: [0, 63, 127], 155: [63, 95, 127],
    156: [0, 38, 76], 157: [38, 57, 76], 158: [0, 19, 38], 159: [19, 28, 38], 160: [0, 63, 255],
    161: [127, 159, 255], 162: [0, 41, 165], 163: [82, 103, 165], 164: [0, 31, 127], 165: [63, 79, 127],
    166: [0, 19, 76], 167: [38, 47, 76], 168: [0, 9, 38], 169: [19, 23, 38], 170: [0, 0, 255],
    171: [127, 127, 255], 172: [0, 0, 165], 173: [82, 82, 165], 174: [0, 0, 127], 175: [63, 63, 127],
    176: [0, 0, 76], 177: [38, 38, 76], 178: [0, 0, 38], 179: [19, 19, 38], 180: [63, 0, 255],
    181: [159, 127, 255], 182: [41, 0, 165], 183: [103, 82, 165], 184: [31, 0, 127], 185: [79, 63, 127],
    186: [19, 0, 76], 187: [47, 38, 76], 188: [9, 0, 38], 189: [23, 19, 38], 190: [127, 0, 255],
    191: [191, 127, 255], 192: [82, 0, 165], 193: [124, 82, 165], 194: [63, 0, 127], 195: [95, 63, 127],
    196: [38, 0, 76], 197: [57, 38, 76], 198: [19, 0, 38], 199: [28, 19, 38], 200: [191, 0, 255],
    201: [223, 127, 255], 202: [124, 0, 165], 203: [145, 82, 165], 204: [95, 0, 127], 205: [111, 63, 127],
    206: [57, 0, 76], 207: [66, 38, 76], 208: [28, 0, 38], 209: [33, 19, 38], 210: [255, 0, 255],
    211: [255, 127, 255], 212: [165, 0, 165], 213: [165, 82, 165], 214: [127, 0, 127], 215: [127, 63, 127],
    216: [76, 0, 76], 217: [76, 38, 76], 218: [38, 0, 38], 219: [38, 19, 38], 220: [255, 0, 191],
    221: [255, 127, 223], 222: [165, 0, 124], 223: [165, 82, 145], 224: [127, 0, 95], 225: [127, 63, 111],
    226: [76, 0, 57], 227: [76, 38, 66], 228: [38, 0, 28], 229: [38, 19, 33], 230: [255, 0, 127],
    231: [255, 127, 191], 232: [165, 0, 82], 233: [165, 82, 124], 234: [127, 0, 63], 235: [127, 63, 95],
    236: [76, 0, 38], 237: [76, 38, 57], 238: [38, 0, 19], 239: [38, 19, 28], 240: [255, 0, 63],
    241: [255, 127, 159], 242: [165, 0, 41], 243: [165, 82, 103], 244: [127, 0, 31], 245: [127, 63, 79],
    246: [76, 0, 19], 247: [76, 38, 47], 248: [38, 0, 9], 249: [38, 19, 23], 250: [84, 84, 84],
    251: [118, 118, 118], 252: [160, 160, 160], 253: [192, 192, 192], 254: [224, 224, 224], 255: [255, 255, 255]
  },
  obtenhaCorCADparaRGB (codigo) {
    if (this.tabela[codigo] === undefined)
      return false;
    let retorno = this.tabela[codigo];
    let retornoStr = "rgb(" + retorno.join(", ") + ")";
    return retornoStr;
  },

  obtenhaCorRGBparaCAD (vermelho, verde, azul) {
    if (vermelho > 255 || verde > 255 || azul > 255)
      return false;
    let melhor = 1000;
    let correspondente = 0;
    for (let i = 0; i < 256; i++) {
      let corIteracao = this.tabela[i];
      let r = Math.pow(vermelho - corIteracao[0], 2);
      let g = Math.pow(vermelho - corIteracao[1], 2);
      let b = Math.pow(vermelho - corIteracao[2], 2);
      let distancia = Math.sqrt(r + g + b);
      if (distancia < melhor) {
        melhor = distancia;
        correspondente = i;
      }
    }
    return correspondente;
  }

}
export default ConversaoCores;
