import { Text } from "@svgdotjs/svg.js";
const ParseTexto = function (entidade, layers) {
  let layer = entidade[8];
  let tamanhoTexto = entidade[2] ?? 1;
  let x = entidade[0][0];
  let y = entidade[0][1] - (2 * entidade[0][1] - tamanhoTexto / 2);
  let textoString = entidade[1] ?? "";
  let orientacaoHorizontal = definirAlinhamentoHorizontal(entidade);
  let orientacaoVertical = definirAlinhamentoVertical(entidade);
  let angulo = entidade[5] ?? 0;
  if (orientacaoVertical == "middle" && angulo > 0)
    x += tamanhoTexto / 2;
  let retorno = new Text({
    x: x,
    y: y,
    "font-size": tamanhoTexto,
    "alignment-baseline": orientacaoVertical,
    "text-anchor": orientacaoHorizontal
  });
  retorno.text(formatarTexto(textoString));
  retorno.fill(layers[layer]["cor"]);
  retorno.transform({ rotate: -angulo });
  return retorno;
}

const formatarTexto = function (texto) {
  let expressaoRegular = new RegExp("([\\U+]{2}\\w{4})");
  let retorno = texto;
  if (expressaoRegular.test(texto)) {
    let res = texto.match(expressaoRegular);
    for (let i = 0; i <= res.length; i++) {
      let char = parseInt(res[1].slice(-4), 16);
      retorno = retorno.replace(expressaoRegular, String.fromCharCode(char));
    }

  }
  return retorno.replace("\\", "");
}


const definirAlinhamentoHorizontal = function (entidade) {
  let valor = entidade[3] ?? 0;
  if (valor === undefined || valor == 0)
    return "start";
  else if (valor == 1)
    return "middle";
  return "end";
}

const definirAlinhamentoVertical = function (entidade) {
  let valor = entidade[4] ?? 0;
  if (valor === undefined || valor == 0 || valor == 1)
    return "baseline";
  else if (valor == 2)
    return "middle"
  return "hanging";
}

export default ParseTexto;
