import Axios from "axios";
import Visualizacao from "@/Plugin/Desenho/visualizacao";
import mixin from "./../../shared/mixin";
import mixinEstrutural from "./mixinsEstrutural";
import Loader from "@/views/Componentes/loader";
const mixinVisualizacao = {
  components: { Loader },
  mixins: [mixin, mixinEstrutural],
  props: {
    show: { type: Boolean, default: false }
  },
  data () {
    return {
      requisicaoAberta: false,
      requisicaoVerificao: false,
      titulo: "",
      guiaAtivo: 0,
      bitolasCriterio: [],
      fundacaoAtiva: {
        carregado: false
      }
    }
  },
  methods: {
    fecharVisualizacao () {
      this.$eventoHub.$emit("fecharJanelaVisualizacao", []);
    },
    obtenhaDesenho (idFundacao) {
      let url = this.montarURLRequisicao(idFundacao, "desenho");
      this.requisicaoAberta = true;
      Axios.get(url)
        .then(resposta => {
          Visualizacao.ParseDesenho(resposta.data.retorno.desenho);
          Visualizacao.zoomExtend();
          let fundacao = resposta.data.retorno.fundacao;
          this.titulo = fundacao.identificacao;
          this.aplicarDadosFundacaoAtiva(fundacao);
        })
        .catch(erro => { })
        .finally(() => {
          this.requisicaoAberta = false;
          this.fundacaoAtiva.carregado = true;
        });
    },
    aplicarDadosFundacaoAtiva (fundacao) {
      Object.keys(fundacao).forEach(element => {
        this.$delete(this.fundacaoAtiva, element, fundacao[element]);
        this.$set(this.fundacaoAtiva, element, fundacao[element]);
      });
    },
    obtenhaItensBitola () {
      let criterio = null;
      if (this.$sessionStorage.hasKey("criterio")) {
        criterio = (this.$sessionStorage.get("criterio")).dados;
      }
      if (Array.isArray(criterio[10])) {
        this.bitolasCriterio = criterio[10].map(val => {
          return {
            value: val,
            label: `Ø ${val} mm`,
            area: Math.round(Math.PI * val * val) / 4 / 100
          };
        });
      }
    },
    montarURLRequisicao (idFundacao, destino) {
      let idEstrutural = this.obtenhaIdEstrutural();
      let idProjeto = this.obtenhaIdProjeto();
      return `projeto/${idProjeto}/estrutural/${idEstrutural}/fundacao/${idFundacao}/${destino}/`;

    },
    verificarFundacao () {
      this.requisicaoVerificao = true;
      let fundacao = this.fundacaoAtiva;
      fundacao.desenho = true;
      let url = this.montarURLRequisicao(fundacao.id, "verificacao");
      Axios.post(url, fundacao)
        .then(resposta => {
          let retorno = resposta.data.retorno;
          if (retorno.verificado) {
            this.$eventoHub.$emit("adicionarMensagemAviso", {
              mensagem: "A fundação passou na verificação",
              classe: "bg-success"
            });
          } else {
            this.$eventoHub.$emit("adicionarMensagemAviso", {
              mensagem: "A fundação não passou na verificação",
              classe: "bg-danger"
            });
          }
          Visualizacao.ParseDesenho(retorno.desenho);
        })
        .finally(() => {
          this.requisicaoVerificao = false;
        });
    },
    salvarAlteracao () {
      this.requisicaoAberta = true;
      let fundacao = this.fundacaoAtiva;
      let url = this.montarURLRequisicao(fundacao.id, "salvar-alteracao");
      Axios.post(url, fundacao)
        .then(() => {
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "Os dados foram salvos",
            classe: "bg-success"
          });
        })
        .catch(() => {
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "Os não foram salvos",
            classe: "bg-danger"
          });
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    redefinirEstrutural () {
      this.requisicaoAberta = true;
      let fundacao = this.fundacaoAtiva;
      let url = this.montarURLRequisicao(fundacao.id, "redefinir");
      Axios.get(url)
        .then(resposta => {
          let retorno = resposta.data.retorno;
          this.aplicarDadosFundacaoAtiva(retorno.fundacao);
          Visualizacao.ParseDesenho(retorno.desenho);
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "A fundação foi redefinida",
            classe: "bg-success"
          });
        })
        .catch((erro) => {
          console.log(erro);
          this.$eventoHub.$emit("adicionarMensagemAviso", {
            mensagem: "Os não foram salvos",
            classe: "bg-danger"
          });
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  },
  beforeMount () {
    this.obtenhaItensBitola();
  },
  mounted () {
    Visualizacao.informarContainer(this.$refs.vizualizador);
  },
  created () {
    this.$eventoHub.$on("exibirDesenho", this.obtenhaDesenho);

  },
  destroyed () {
    this.$eventoHub.$off("exibirDesenho", this.obtenhaDesenho);
  }
}


export default mixinVisualizacao;
