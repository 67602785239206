import ConversaoCores from "./coresCad";
const Utilitarios = {
  StrokeObjeto (layer, layers) {
    if (layers[layer] !== undefined) {
      return { color: layers[layer]["cor"], width: layers[layer]["espessura"] };
    }
    return { color: ConversaoCores.obtenhaCorCADparaRGB(0), width: 1 };
  },
  GrausParaRadiano (grau) {
    return grau * Math.PI / 180;
  },
  ConversaoCores: ConversaoCores,
  TranformarEixoY: { a: 1, b: 0, c: 0, d: -1, e: 1, f: 1 }
}
export default Utilitarios;
