import { Rect } from "@svgdotjs/svg.js";
import Utilitarios from "./../Util";

const ParseRetangulo = function (entidade, layers) {
  let x = entidade[0][0];
  let y = entidade[0][1];
  let largura = entidade[1] ?? null;
  let altura = entidade[2] ?? null;
  let retorno = new Rect({ x: x, y: y, width: largura, height: altura });
  retorno.fill("none");
  retorno.stroke(Utilitarios.StrokeObjeto(entidade[8], layers));
  retorno.transform(Utilitarios.TranformarEixoY);
  return retorno;
}

export default ParseRetangulo;
