import ParseTexto from "./Texto";
import ParseArco from "./Arco";
import ParsePolilinha from "./Polilinha";
import ParseLinha from "./Linha";
import ParseCirculo from "./Circulo";
import ParseRetangulo from "./Retangulo";
const Elementos = {
  Texto: ParseTexto,
  Arco: ParseArco,
  Polilinha: ParsePolilinha,
  Linha: ParseLinha,
  Circulo: ParseCirculo,
  Retangulo: ParseRetangulo
}


export default Elementos;
