import { Line } from "@svgdotjs/svg.js";
import Utilitarios from "./../Util";

const ParseLinha = function (entidade, layers) {
  let x = entidade[0][0];
  let y = entidade[0][1];

  let x2 = entidade[2][0] ?? null;
  let y2 = entidade[2][1] ?? null;
  let retorno = new Line({ x1: x, y1: y, x2: x2, y2: y2 });
  retorno.stroke(Utilitarios.StrokeObjeto(entidade[8], layers));
  retorno.transform(Utilitarios.TranformarEixoY);
  return retorno;
}

export default ParseLinha;
