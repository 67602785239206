import { SVG } from "@svgdotjs/svg.js";
import '@svgdotjs/svg.panzoom.js'
import ConversaoCores from "./coresCad";
import Elementos from "./Elementos/Index";
const ParseLayer = function (dados) {
  dados.forEach((layer, indice) => {
    let retorno = layer;
    retorno.cor = ConversaoCores.obtenhaCorCADparaRGB(layer.cor);
    Visualizacao.layer[indice] = retorno;
  });
};
let DuploCliqueMouse = 0;

const mouseDoubleClick = new Event("cliqueDuplo");
const mouseCapturaDuploClick = function (event) {
  event.preventDefault();
  if (event.button == 1) {
    if (DuploCliqueMouse === 0) {
      setTimeout(() => {
        DuploCliqueMouse = 0;
      }, 500);
    }
    DuploCliqueMouse++;
    if (DuploCliqueMouse === 3) {
      DuploCliqueMouse = 0;
      this.dispatchEvent(mouseDoubleClick);
    }
  }
}
const ParseObjetos = function (dados) {
  let grupo = Visualizacao.svg.group();
  dados.forEach(element => {
    let entidade = Visualizacao.ParseEntidade(element);
    entidade.attr({ "shape-rendering": "crispEdges" })
    if (entidade !== null)
      grupo.add(Visualizacao.ParseEntidade(element));
  });
};
const Visualizacao = {
  entidades: [],
  layer: [],
  svg: SVG(),
  container: "",
  ParseDesenho (dados) {
    this.svg.clear();
    ParseLayer(dados.layer);
    ParseObjetos(dados.objetos);
  },
  ParseEntidade (entidade) {
    let tipo = entidade[100];
    switch (tipo) {
      case "linha": {
        return Elementos.Linha(entidade, this.layer);
      }
      case "polilinha": {
        return Elementos.Polilinha(entidade, this.layer);
      }
      case "arco": {
        return Elementos.Arco(entidade, this.layer);
      }
      case "circulo": {
        return Elementos.Circulo(entidade, this.layer);
      }
      case "retangulo": {
        return Elementos.Retangulo(entidade, this.layer);
      }
      case "texto": {
        return Elementos.Texto(entidade, this.layer);
      }
      default:
        return null;
    }
  },
  informarContainer (container) {
    this.container = container;
    this.svg = SVG().addTo(container).size("100%", "100%").viewbox(-10, -10, 20, 20).panZoom({ panButton: 1 });
    container.addEventListener("mousedown", mouseCapturaDuploClick);
    container.addEventListener("mouseup", mouseCapturaDuploClick);
    container.addEventListener("cliqueDuplo", this.zoomExtend);
    container.addEventListener("contextmenu", (event) => { event.preventDefault() });
  },
  zoomExtend () {
    let box = Visualizacao.svg.bbox();
    Visualizacao.svg.animate().viewbox(box);
  },
  zoomExtendEvento (event) {
    event.preventDefault();
    this.zoomExtend();
  }

}
export default Visualizacao;
