import { Path, PathArray } from "@svgdotjs/svg.js";
import ConversaoCores from "./../coresCad";

const ParseArco = function (entidade, layers) {
  let x = entidade[0][0];
  let y = entidade[0][1];
  let raio = entidade[3];
  let anguloInicial = GrausParaRadiano(entidade[1]);
  let anguloFinal = GrausParaRadiano(entidade[2]);
  let xInicial = x + Math.cos(anguloInicial) * raio;
  let yInicial = y + Math.sin(anguloInicial) * raio;
  let xFinal = x + Math.cos(anguloFinal) * raio;
  let yFinal = y + Math.sin(anguloFinal) * raio;
  let path = new PathArray('M ' + xFinal + ',' + yFinal + ' A ' + raio + ',' + raio + ' 0,0,0 ' + xInicial + ',' + yInicial);
  let retorno = new Path({ d: path });
  retorno.stroke(StrokeObjeto(entidade, layers));
  retorno.fill("none");
  retorno.transform({ a: 1, b: 0, c: 0, d: -1, e: 1, f: 1 });

  return retorno;
}

const GrausParaRadiano = function (grau) {
  return grau * Math.PI / 180;
}

const StrokeObjeto = function (entidade, layers) {
  let layer = entidade[8];
  if (layers[layer] !== undefined) {
    return { color: layers[layer]["cor"], width: layers[layer]["espessura"] };
  }
  return { color: ConversaoCores.obtenhaCorCADparaRGB(0), width: 1 };
}


export default ParseArco;
